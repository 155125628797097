import React, { useState, useEffect } from 'react';
import {
  Link,
  useParams,
  useHistory,
} from "react-router-dom";
import moment from 'moment';
import { ReactComponent as Close } from '../../close.svg';
import { returnImageMarkup } from '../../Utils.js';
import ProductGridItem from '../../components/ProductGridItem';
import showdown from 'showdown';
import ErrorNotice from '../../components/ErrorNotice/index.js';
const converter = new showdown.Converter();


export default function Product({ api, addToCart, showMessage, sessionValid }) {
  const [product, setProduct] = useState({});
  const [related, setRelated] = useState([]);
  const [tipVisible, setTipVisible] = useState(false);
  const [previewing, setPreviewing] = useState(false);
  const [ebook, setEbook] = useState(false);
  const [showEbook, setShowEbook] = useState(false);

  let { slug } = useParams();

  let history = useHistory();

  const getProduct = async () => {
    const format = new URLSearchParams(window.location.search).get('format');
    let data = await window.ASTERISM.makeRequest(`public-product?slug=${slug}`);
    document.title = `${data.title} by ${data.authors.join(', ')} | Sublunary Editions`;
    document.querySelector('meta[name="description"]').setAttribute("content", data.description);
    setProduct(data);
    if (data.ebook && data.ebook.stripePrice) {
      setEbook(true);
    }
    if (format == 'ebook') {
      setShowEbook(true);
    }

    if (data.related && data.related.length > 0) {
      let relatedBooks = data.related.split(',');
      let toadd = [];
      for (let i = 0; i < relatedBooks.length; i++) {
        let reldata = await window.ASTERISM.makeRequest(`public-product?id=${relatedBooks[i]}`);
        if (reldata && reldata.cover) {
          toadd.push(reldata);
        }
      }
      setRelated(toadd);
    }
  }

  useEffect(() => {
    getProduct();
  }, [slug]);

  const getFileExtension = (filename) => {
    let parts = filename.split('.');
    let extension = parts[parts.length - 1];
    return extension;
  }


  const prepareContributors = () => {
    let contribs = [];
    if (product.translators.length > 0) {
      product.translators.forEach((person) => {
        contribs.push({
          name: person,
          title: 'translator'
        });
      })
    }
    if (product.editors.length > 0) {
      product.editors.forEach((person) => {
        contribs.push({
          name: person,
          title: 'editor'
        });
      })
    }
    if (product.illustrators.length > 0) {
      product.illustrators.forEach((person) => {
        contribs.push({
          name: person,
          title: 'illustrator'
        });
      })
    }
    return contribs;
  }

  const isPreorder = (product) => {
    return new Date(product.release).setHours(0, 0, 0, 0) >= new Date().getTime();
  }

  if (!product.title) {
    return (
      <div></div>
    )
  }

  return (
    <div>
      <div className='product-page'>

        <div className='product-image'>
          <div className='product-image-cover'>
            <div className='product-image-frame'></div>
            {returnImageMarkup(product.cover, `Cover art for ${product.title}`)}
          </div>
          {product.preview &&
            <div className='mt-24' style={{ display: 'block', textAlign: 'center' }}>
              {window.innerWidth > 799 && <button className='secondary-submit' onClick={() => { setPreviewing(true) }}>Preview This Book</button>}
              {window.innerWidth < 800 && <a className='secondary-submit' href={product.preview}>Preview This Book</a>}
            </div>
          }
          <div className='product-stats desktop'>
            <span className='stat-label'>Pages:</span><div>{product.pages}</div>
            <span className='stat-label'>Format:</span><div>{showEbook ? 'PDF eBook' : product.format}</div>
            <span className='stat-label'>{product.idType == 'custom' ? 'Publisher ID' : product.idType}</span><div>{showEbook ? product.ebook.isbn : product.productId}</div>

            {!showEbook && <span className='stat-label'>Dimensions:</span>}
            {!showEbook && <div>{product.dimension.width}" x {product.dimension.height}"</div>}
          </div>
        </div>
        <div className='product-data'>
          <div className='product-top-meta'>{product.release && moment(product.release).format('LL')}</div>
          <h1>{product.title}</h1>
          <div className='contributors'>
            <span className='authors'>
              {product.authors && product.authors.map((author, index) =>
                <span>{index !== 0 && ', '}<Link to={`/search/${author}`}>{author}</Link></span>
              )}
            </span>
            <span>{product.title && prepareContributors().length > 0 && ' | '}</span>
            <span>{product.authors && prepareContributors().map((contrib, index) =>
              <span>{index !== 0 && ', '}<Link to={`/search/${contrib.name}`}>{contrib.name}</Link> ({contrib.title})</span>
            )}</span>
          </div>
          {ebook &&
            <div className='product-pricing-tabs'>
              <div className={`product-pricing-header ${!showEbook ? 'active' : 'inactive'}`} onClick={() => { setShowEbook(!showEbook) }}>{product.format}</div>
              <div className={`product-pricing-header ${showEbook ? 'active' : 'inactive'}`} onClick={() => { setShowEbook(!showEbook) }}>PDF eBook</div>
            </div>
          }
          {!showEbook && <div className='product-pricing mb-48'>
            <div className='prices'>
              {product.prices && product.prices.list !== product.prices.retail && <span className='higher-price'>${product.prices.list}</span>}
              {product.prices && !product.prices.enableSale && <span className='real-price'>${product.prices.retail}</span>}
              {product.prices && product.prices.enableSale && <span className='real-price sale'>${product.prices.sale} <span className='on-sale'>On sale!</span></span>}
            </div>
            {(isPreorder(product) || product.inventory > 0 || product.asterismLink) &&
              <div className='add-to-cart'>
                {product.asterismLink ? <a className='main-submit mr-12' href={product.asterismLink}>{isPreorder(product) ? 'Pre-order at Asterism' : 'Buy Now at Asterism'}</a> : <button className='main-submit mr-12' onClick={() => { addToCart(product._id, 'books') }}>{isPreorder(product) ? 'Pre-order' : 'Add to cart'}</button>}
                {!product.hideSubscribe && <Link to='/subscribe'><button onMouseOver={() => { setTipVisible(true) }} onMouseOut={() => { setTipVisible(false) }} className='secondary-submit'>Subscribe</button></Link>}
                {tipVisible &&
                  <div className='subscribe tooltip'>
                    {product.categories && product.categories.indexOf('Firmament') == -1 ?
                      'Subscribe to any of our annual or 6-month subscription plans and you can get this title free as a welcome gift!' :
                      'Subscribe to Firmament for as little as $5/mo, including a subscriber-exclusive monthly newsletter.'
                    }
                  </div>
                }
              </div>
            }
            {!isPreorder(product) && product.inventory == 0 &&
              <div className='add-to-cart'><em>This product is currently out of stock. Check back soon!</em></div>
            }
          </div>}

          {product.ebook && product.ebook.stripePrice && showEbook &&
            <div className='product-pricing mb-48'>
              <div className='prices'>
                <span className='real-price'>${product.ebook.price}</span>
              </div>
              <div className='add-to-cart'>
                <button className='main-submit mr-12' onClick={() => { addToCart(product._id, 'ebooks') }}>Add to cart</button>
              </div>
            </div>
          }



          <div className='product-description' dangerouslySetInnerHTML={{ __html: converter.makeHtml(product.description) }}></div>

          <div className='product-stats mobile'>
            <span className='stat-label'>Pages:</span><div>{product.pages}</div>
            <span className='stat-label'>Format:</span><div>{showEbook ? 'PDF eBook' : product.format}</div>
            <span className='stat-label'>{product.idType == 'custom' ? 'Publisher ID' : product.idType}</span>
            <div>{showEbook ? product.ebook.isbn : product.productId}</div>

            {!showEbook && <span className='stat-label'>Dimensions:</span>}
            {!showEbook && <div>{product.dimension.width}" x {product.dimension.height}"</div>}
          </div>

          {product.supplements && product.supplements.length > 0 && <div>
            <h3>Digital Supplements</h3>
            {product.supplements.map((extra) =>
              <div className='mb-24' key={extra.location}>
                <div><strong>{extra.name ? extra.name : extra.location}</strong> ({getFileExtension(extra.location)})</div>
                <div>{extra.description}</div>
                <div><a href={extra.location} target='blank'>View / Download</a></div>
              </div>
            )}
          </div>}
        </div>
        {previewing &&
          <div className='modal'>
            <div className='modal-content'>
              <div className='standard-header'>
                <h2><span>Preview of <em>{product.title}</em></span></h2>

                <button className='secondary-submit close' onClick={() => { setPreviewing(false) }}><Close /></button>
              </div>
              <object
                data={product.preview}
                width="100%"
                height="92%">
              </object>
            </div>
          </div>
        }
      </div>
      {related.length > 0 && <div className='generic-block flex'>
        <h2>Related</h2>
      </div>}
      <div className='product-grid'>
        {related.map((product) =>
          <ProductGridItem key={product._id} product={product} home={true} />
        )}
      </div>
    </div>
  )
}
