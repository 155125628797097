import React, { useEffect, useState } from 'react';

export default function BinaryControl({ value, change, label = '', help = '', options = ['Yes', 'No'], classList = null, disabled = false }) {
  return (
    <div className={`text-control ${classList}`}>
      {label && <label className='form-label'>{label}</label>}
      {!disabled && <div className='binary-chooser'>
        <div className={`binary-opt ${value === true && 'chosen'}`} onClick={() => { change(true) }}>{options[0]}</div>
        <div className={`binary-opt ${value === false && 'chosen'}`} onClick={() => { change(false) }}>{options[1]}</div>
      </div>}
      {disabled && <div className='binary-chooser disabled'>
        <div className={`binary-opt ${value === true && 'chosen'}`}>{options[0]}</div>
        <div className={`binary-opt ${value === false && 'chosen'}`}>{options[1]}</div>
      </div>}
      {help && <div className='form-help'>{help}</div>}
    </div>
  )
}
