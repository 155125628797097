import React, { useState, useEffect } from 'react';
import {
  Link
} from "react-router-dom";
import moment from 'moment';
import ProductGridItem from '../../components/ProductGridItem';
import Newsletter from '../../components/Newsletter';
import showdown from 'showdown';
import ErrorNotice from '../../components/ErrorNotice';
const converter = new showdown.Converter();

export default function Home({ api, showMessage, addToCart }) {
  const [books, setBooks] = useState([]);
  const [futureBooks, setFutureBooks] = useState([]);
  const [news, setNews] = useState([]);
  const [posts, setPosts] = useState([]);
  const [empyrean, setEmpyrean] = useState([]);
  const [poetry, setPoetry] = useState([]);

  const getBooks = async () => {
    let data = await window.ASTERISM.makeRequest('public-products-new', 'GET');
    setBooks(data);
    let updata = await window.ASTERISM.makeRequest('public-products-upcoming', 'GET');
    setFutureBooks(updata);
    let emdata = await window.ASTERISM.makeRequest('list-all-books?category=Empyrean&per=4&page=0', 'GET');
    setEmpyrean(emdata.books);
    let poetryData = await window.ASTERISM.makeRequest('list-all-books?category=Poetry&per=4&page=0', 'GET');
    setPoetry(poetryData.books);
  }

  const getPosts = async () => {
    let data = await window.ASTERISM.makeRequest(`list-all-news?page=0&per=3`);
    setNews(data);
    let elseData = await window.ASTERISM.makeRequest(`list-all-else?page=0&per=3`);
    setPosts(elseData);
  }

  useEffect(() => {
    getBooks();
    getPosts();
  }, [])

  document.title = 'Sublunary Editions | Independent Since 2019';
  document.querySelector('meta[name="description"]').setAttribute("content", 'Seattle-based publisher of literary fiction, poetry, criticism, and more.');

  return (
    <div>
      <div className='home-banner'>
        <div className='home-banner-image'>
          <img src='./splash1.png' />
          <div className='home-banner-text' style={{ backgroundImage: 'url(./splash_orange.png)' }}>
            <div className="home-quote-big light">“Poetry is always sublunary.” <div className='citation'>—Julio Cortázar</div></div>
          </div>
        </div>
        <div className='home-banner-image'>
          <img src='./splash2.png' />
          <div className='home-banner-text' style={{ backgroundImage: 'url(./splash_navy.png)' }}>
            <div className="home-info-medium light">Sublunary Editions is an independent press based in Seattle, Washington, publishing small volumes of exciting literature.</div>
          </div>
        </div>
        <div className='home-banner-image'>
          <img src='./splash11.png' />
          <div className='home-banner-text' style={{ backgroundImage: 'url(./splash_blue.png)' }}>
            <div className="home-quote-medium light">“The longer a book is, the less literature it has.” <div className='citation'>—César Aira</div></div>
          </div>
        </div>
        <div className='home-banner-image'>
          <img src='./splash13.png' />
          <div className='home-banner-text' style={{ backgroundImage: 'url(./splash_tan.png)' }}>
            <div className="home-info-medium dark">Through our Empyrean series, we publish reprints and fresh compendiums of great unsung works from the annals of literary history.</div>
          </div>
        </div>
        <div className='home-banner-image'>
          <img src='./splash5.png' />
          <div className='home-banner-text' style={{ backgroundImage: 'url(./splash_blue.png)' }}>
            <div className="home-quote-medium light">“So can wolves and leopards be heard, when, in the icy depths of winter, they bellow at the firmament [...]” <div className='citation'>—Heinrich von Kleist, tr. Spencer</div></div>
          </div>
        </div>
        <div className='home-banner-image'>
          <img src='./splash9.png' />
          <div className='home-banner-text' style={{ backgroundImage: 'url(./splash_tan.png)' }}>
            <div className="home-info-medium dark">We also publish <em>Firmament</em>, a quarterly magazine of international writing edited by Jessica Sequeira.</div>
          </div>
        </div>
        <div className='home-banner-image'>
          <img src='./splash6.png' />
          <div className='home-banner-text' style={{ backgroundImage: 'url(./splash_orange.png)' }}>
            <div className="home-quote-small light">“To be a miscreant: a pretty good disposition for a roman-fleuve: the miscreant is sublunary, terrestrial to the bone [...]” <div className='citation'>—Pierre Senges, tr. Siefring</div></div>
          </div>
        </div>
        <div className='home-banner-image'>
          <img src='./splash10.png' />
          <div className='home-banner-text' style={{ backgroundImage: 'url(./splash_navy.png)' }}>
            <div className="home-quote-small light">“Ambition, Disdaine, Malice, difference of Opinions, can not approach this Place, resembling those foggie mists, which cover those Lists of sublunarie things.” <div className='citation'>—William Drummond of Hawthornden</div></div>
          </div>
        </div>
      </div>

      <div className='standard-header naked'>
        <h2>New Releases</h2>
        <Link className='see-more-link' to='/all-books'>See All Books</Link>
      </div>


      <div className='product-grid'>
        {books && books.map((product) =>
          <ProductGridItem key={product._id} product={product} home={true} showMessage={showMessage} addToCart={addToCart} />
        )}
      </div>

      <div className='standard-header naked'>
        <h2>Upcoming Releases</h2>
        <Link className='see-more-link' to='/all-books'>See All Books</Link>
      </div>

      <div className='product-grid'>
        {futureBooks && futureBooks.map((product) =>
          <ProductGridItem key={product._id} product={product} home={true} showMessage={showMessage} addToCart={addToCart} />
        )}
      </div>

      <div className='admin-full no-v'>
        <div className='standard-header'>
          <h2>From the Magazine</h2>
          <Link className='see-more-link' to='/magazine'>See More</Link>
        </div>
        <div className='post-grid'>
          {posts.map((post) =>
            <div key={post._id} className='post-grid-item'>
              <div className='post-grid-frame'></div>
              <div className='post-grid-item-content'>
                <div className='subtitle'>{post.published}</div>
                <span className='title'><Link className='hov' to={`/magazine/${post.slug}`}>{post.title}</Link></span>
                <div className='subtitle'>by {post.byline}</div>
                <div className='excerpt' dangerouslySetInnerHTML={{ __html: post.excerpt }}></div>
                <Link to={`/magazine/${post.slug}`} className='read-more-link'>Read More</Link>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className='standard-header naked'>
        <h2>Empyrean Series</h2>
        <Link className='see-more-link' to='/empyrean'>See All</Link>
      </div>
      <div className='product-grid'>
        {empyrean && empyrean.map((product) =>
          <ProductGridItem key={product._id} product={product} home={true} showMessage={showMessage} addToCart={addToCart} />
        )}
      </div>

      <div className='admin-full no-v'>
        <div className='standard-header'>
          <h2>Latest News</h2>
          <Link className='see-more-link' to='/news'>See All News</Link>
        </div>
        <div className='post-grid'>
          {news.map((post) =>
            <div key={post._id} className='post-grid-item'>
              <div className='post-grid-frame'></div>
              <div className='post-grid-item-content'>
                <div className='subtitle'>{post.published}</div>
                <span className='title'><Link className='hov' to={`/news/${post.slug}`}>{post.title}</Link></span>
                <div className='subtitle'>by {post.byline}</div>
                <div className='excerpt' dangerouslySetInnerHTML={{ __html: post.excerpt }}></div>
                <Link to={`/news/${post.slug}`} className='read-more-link'>Read More</Link>
              </div>
            </div>
          )}
        </div>
      </div>


      <div className='generic-block flex'>
        <h2>Poetry</h2>
      </div>
      <div className='product-grid'>
        {poetry.map((product) =>
          <ProductGridItem key={product._id} product={product} home={true} showMessage={showMessage} addToCart={addToCart} />
        )}
      </div>
    </div>
  )
}
