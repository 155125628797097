import React, { useEffect, useState, Fragment } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  withRouter,
} from "react-router-dom";
import moment from 'moment';

import Home from './pages/Home/index.js';
import Firmament from './pages/Firmament/index.js';
import Empyrean from './pages/Empyrean/index.js';
import Subscribe from './pages/Subscribe/index.js';
import GiftSubs from './pages/GiftSubs/index.js';

import AccountHome from './pages/AccountHome';
import AccountEdit from './pages/AccountEdit';

import AccountProducts from './pages/AccountProducts';
import AccountProductEdit from './pages/AccountProductEdit';
import AccountProductAdd from './pages/AccountProductAdd';

import AccountBundles from './pages/AccountBundles';
import AccountBundleEdit from './pages/AccountBundleEdit';
import AccountBundleAdd from './pages/AccountBundleAdd';

import AccountSubs from './pages/AccountSubs';
import AccountSubAdd from './pages/AccountSubAdd';
import AccountSubEdit from './pages/AccountSubEdit';

import AccountOrders from './pages/AccountOrders';
import AccountStatements from './pages/AccountStatements';

import AccountPosts from './pages/AccountPosts';
import AccountPostEdit from './pages/AccountPostEdit';
import AccountPostAdd from './pages/AccountPostAdd';

//import AccountPosts from './pages/AccountPosts';
import AccountOutletEdit from './pages/AccountOutletEdit';
import AccountOutletAdd from './pages/AccountOutletAdd';

import AccountPitches from './pages/AccountPitches';

//import AccountPosts from './pages/AccountPosts';
import AccountPersonEdit from './pages/AccountPersonEdit';
import AccountPersonAdd from './pages/AccountPersonAdd';

import AccountPages from './pages/AccountPages';
import AccountPageEdit from './pages/AccountPageEdit';
import AccountPageAdd from './pages/AccountPageAdd';

import Login from './pages/AuthLogin';
import Forgot from './pages/AuthForgot';
import Reset from './pages/AuthReset';
import Change from './pages/AuthChange';

import CreateAccount from './pages/CreateAccount';

import Product from './pages/Product';
import ProductFool from './pages/ProductAF';
import Bundle from './pages/Bundle';
import Post from './pages/Post';
import Page from './pages/Page';
import Cart from './pages/Cart';
import Search from './pages/Search';
import Magazine from './pages/Magazine';
import MagazineArchive from './pages/MagazineArchive';
import News from './pages/News';
import BooksAll from './pages/BooksAll';
import EbooksAll from './pages/EbooksAll';
import Bundles from './pages/Bundles';
import Barcode from './pages/Barcode';

import ReviewRequest from "./pages/RequestReviews/index.js";

import Message from './components/Message';

import ScrollToTop from './components/ScrollToTop';

import { ReactComponent as Logo } from './insignia.svg';
import { MainMenu, AdminMenu } from './components/Menu';
import NewMenu from './components/MainMenu';

import Catalogue from './pages/Catalogue';
import SocialTool from "./pages/ToolsSocial/index.js";

import Newsletter from './components/Newsletter';

import { getMenus, getMainMenu } from './Menus.js';
import { makeRequest, uploadFile } from './Utils.js';

//const api = 'http://localhost:3009/';
const api = 'https://sublunaryeditions.com/api/';

window.ASTERISM = {
  api: api,
  makeRequest: makeRequest,
  uploadFile: uploadFile,
};

export default function App() {
  const [cartCount, setCartCount] = useState(0);
  const [search, setSearch] = useState('');
  const [sessionValid, setSessionValid] = useState(false);
  const [userName, setUserName] = useState('');
  const [userId, setUserId] = useState(null);
  const [roles, setRoles] = useState({ publisher: false, customer: true });
  const [userMenu, setUserMenu] = useState([]);
  const [menu, setMenu] = useState(false);
  const [menuOpen, setMenuOpen] = useState(true);
  const [settings, setSettings] = useState(null);

  const getSettings = async () => {
    let set = await fetch(`${api}settings`);
    let data = await set.json();
    setSettings(data);
  }

  /**
  * Let's check that auth status!
  *
  */
  const checkAuth = async (id = null) => {
    console.log('Hiiiii')
    const token = localStorage.getItem('asToken');
    if (!token) {
      setSessionValid(sessionValid => false);
      getSettings();
      return false;
    } else {
      try {
        let validate = await fetch(`${api}validate?token=${token}`);
        let data = await validate.json();
        if (data.settings) {
          setSettings(data.settings);
        }
        if (!data.valid) {
          setSessionValid(sessionValid => false);
          return false;
          localStorage.setItem('asToken', '');
        } else {
          setSessionValid(sessionValid => true);
          setUserName(data.name);
          setRoles(data.roles);
          setUserId(data.id);
          setUserMenu(getMenus(data.roles));
          return true;
        }
      } catch (err) {
        getSettings();
      }
    }
  }

  const clearCart = () => {
    let cart = {};
    cart.count = 0;
    cart.books = {};
    cart.subs = {};
    localStorage.setItem('seCart', JSON.stringify(cart));
    setCartCount(cart.count);
  }

  const addToCart = async (id, type, qty = 1, remove = false) => {
    if (qty > 0) {
      window.plausible('AddToCart');
    }
    let data = localStorage.getItem('seCart');
    if (data) {
      data = JSON.parse(data);
    } else {
      data = null;
    }
    let cart;
    if ((!data || !data[type]) && qty > 0) {
      showMessage({ text: 'Item has been added to your <a href="/cart">cart</a>!', temp: true, type: 'info', label: 'Good choice!' });
      cart = {};
      cart.count = 0;
      cart.books = {};
      cart.subs = {};
      cart.bundles = {};
      cart.ebooks = {};
      cart[type][id] = qty;
      cart.count += qty;

    } else if (!data[type][id] && qty > 0) {
      showMessage({ text: 'Item has been added to your <a href="/cart">cart</a>!', temp: false, type: 'info', label: 'Good choice!' });
      cart = data;
      cart[type][id] = qty;
      cart.count += qty;
    } else if (remove) {
      showMessage({ text: 'Item has been removed from your cart', temp: true, type: 'info', label: 'Cart Update' });
      cart = data;
      cart.count -= cart[type][id];
      delete cart[type][id];
    } else {
      if (qty < 1) {
        showMessage({ text: 'Item has been removed from your cart', temp: true, type: 'info', label: 'Cart Update' });
      } else {
        showMessage({ text: 'Item has been added to your <a href="/cart">cart</a>!', temp: false, type: 'info', label: 'Good choice!' });
      }
      cart = data;
      cart[type][id] += qty;
      cart.count += qty;
    }
    localStorage.setItem('seCart', JSON.stringify(cart));
    setCartCount(cart.count);
    return cart;
  }

  useEffect(() => {
    const fromCheckout = new URLSearchParams(window.location.search).get('checkout');
    const customerSource = new URLSearchParams(window.location.search).get('source');
    if (fromCheckout === 'success') {
      showMessage({ text: 'Thanks for your order! You should receive an email receipt shortly.', temp: false, type: 'success', label: 'Thank you!' });
      clearCart();
    }
    if (customerSource) {
      localStorage.setItem('seSrc', customerSource);
      window.location = window.location.pathname; // Let's not show that to the user
    }
    checkAuth();
    const data = localStorage.getItem('seCart');
    if (data) {
      let cart = JSON.parse(data);
      if (cart && cart.count) {
        setCartCount(cart.count);
      }
    }
  }, []);


  const gosearching = () => {
    window.location.replace(`//${window.location.host}/search/${encodeURI(search)}`);
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      gosearching();
    }
  }

  const logout = () => {
    localStorage.setItem('asToken', '');
    window.location = window.location.protocol + '//' + window.location.host + '/login';
  }

  const [banner, setBanner] = useState({});

  const showMessage = (config) => {
    setBanner(banner => config);
    if (config.temp) {
      setTimeout(() => {
        let blank = {};
        setBanner(blank);
      }, 3500)
    }
  }

  const cartClick = () => {
    window.plausible('CartClick');
  }

  return (
    <Router>
      <div className="app-container">
        <div className='header-container'>
          {settings !== null && settings.bannerOn && <div className="banner">{settings.banner}</div>}
          <div className="header">
            <div className='the-logo'><Link className='logo-link' to='/'><Logo className='insignia' /> <div className='wordmark'><span className='pressname'>Sublunary Editions</span><span>Seattle, WA</span></div></Link></div>
            <div className='header-right'>
              <div className='top-right'>
                <div className='search-container'>
                  <input className='search-box' value={search} placeholder='Search' onChange={(e) => { setSearch(e.target.value) }} onKeyDown={handleKeyDown} />
                </div>
                <div className='cart-tag'><Link onClick={cartClick} className='cart-text' to='/cart'>Cart</Link><Link onClick={cartClick} className='cart-count' to='/cart'>{cartCount}</Link></div>
              </div>
              <NewMenu sessionValid={sessionValid} search={search} setSearch={setSearch} onKeyDown={handleKeyDown} gosearching={gosearching} />
            </div>
          </div>
        </div>
        <Fragment>
          <ScrollToTop />
          <Switch>
            <Route exact path="/">
              <div className='page-container'>
                <Home api={api} showMessage={showMessage} addToCart={addToCart} />
              </div>
            </Route>
            <Route exact path="/firmament">
              <div className='page-container'>
                <Firmament api={api} showMessage={showMessage} addToCart={addToCart} />
              </div>
            </Route>
            <Route exact path="/empyrean/isle-of-the-cross">
              <div className='page-container'>
                <ProductFool />
              </div>
            </Route>
            <Route exact path="/empyrean/page/:page">
              <div className='page-container'>
                <Empyrean api={api} showMessage={showMessage} addToCart={addToCart} />
              </div>
            </Route>
            <Route exact path="/empyrean">
              <div className='page-container'>
                <Empyrean api={api} showMessage={showMessage} addToCart={addToCart} />
              </div>
            </Route>
            <Route exact path="/subscribe">
              <div className='page-container'>
                <Subscribe api={api} addToCart={addToCart} />
              </div>
            </Route>
            <Route exact path="/gift-subscriptions">
              <div className='page-container'>
                <GiftSubs api={api} addToCart={addToCart} />
              </div>
            </Route>
            <Route exact path="/login">
              <div className='page-container'>
                <MainMenu sessionValid={sessionValid} roles={roles} />
                <Login api={api} checkAuth={checkAuth} sessionValid={sessionValid} />
              </div>
            </Route>
            <Route exact path="/forgot-password">
              <div className='page-container'>
                <MainMenu sessionValid={sessionValid} roles={roles} />
                <Forgot api={api} checkAuth={checkAuth} sessionValid={sessionValid} />
              </div>
            </Route>
            <Route exact path="/change-password">
              <div className='page-container'>
                <MainMenu sessionValid={sessionValid} roles={roles} />
                <Change api={api} checkAuth={checkAuth} sessionValid={sessionValid} showMessage={showMessage} />
              </div>
            </Route>
            <Route exact path="/reset">
              <div className='page-container'>
                <MainMenu sessionValid={sessionValid} roles={roles} />
                <Reset checkAuth={checkAuth} sessionValid={sessionValid} showMessage={showMessage} />
              </div>
            </Route>
            <Route exact path="/cart">
              <div className='page-container'>
                <Cart api={api} setCartCount={setCartCount} addToCart={addToCart} sessionValid={sessionValid} roles={roles} clearCart={clearCart} showMessage={showMessage} settings={settings} />
              </div>
            </Route>

            <Route exact path="/request-review-copy">
              <div className='page-container'>
                <ReviewRequest showMessage={showMessage} />
              </div>
            </Route>

            <Route exact path="/empyrean-catalogue">
              <div className='page-container'>
                <Catalogue />
              </div>
            </Route>

            <Route exact path="/search/:term">
              <div className='page-container'>
                <Search showMessage={showMessage} addToCart={addToCart} />
              </div>
            </Route>
            <Route exact path="/all-books">
              <div className='page-container'>
                <BooksAll api={api} showMessage={showMessage} addToCart={addToCart} />
              </div>
            </Route>
            <Route exact path="/all-books/page/:page">
              <div className='page-container'>
                <BooksAll api={api} showMessage={showMessage} addToCart={addToCart} />
              </div>
            </Route>
            <Route exact path="/all-ebooks">
              <div className='page-container'>
                <EbooksAll api={api} showMessage={showMessage} addToCart={addToCart} />
              </div>
            </Route>
            <Route exact path="/all-bundles">
              <div className='page-container'>
                <Bundles api={api} showMessage={showMessage} addToCart={addToCart} />
              </div>
            </Route>
            <Route exact path="/magazine">
              <div className='page-container'>
                <Magazine api={api} />
              </div>
            </Route>
            <Route exact path="/magazine/page/:page">
              <div className='page-container'>
                <MagazineArchive api={api} />
              </div>
            </Route>
            <Route exact path="/news">
              <div className='page-container'>
                <News api={api} />
              </div>
            </Route>
            <Route exact path="/products/:slug">
              <div className='page-container'>
                <Product api={api} addToCart={addToCart} sessionValid={sessionValid} showMessage={showMessage} />
              </div>
            </Route>
            <Route exact path="/bundles/:slug">
              <div className='page-container'>
                <Bundle api={api} addToCart={addToCart} sessionValid={sessionValid} showMessage={showMessage} />
              </div>
            </Route>
            <Route exact path="/magazine/:slug">
              <div className='page-container'>
                <Post api={api} />
              </div>
            </Route>
            <Route exact path="/news/:slug">
              <div className='page-container'>
                <Post api={api} />
              </div>
            </Route>
            <Route exact path="/page/:slug">
              <div className='page-container'>
                <Page api={api} />
              </div>
            </Route>

            <Route exact path="/my-account">
              <div className='page-container'>
                <AdminMenu sessionValid={sessionValid} roles={roles} logout={logout} userName={userName} />
                <AccountHome api={api} checkAuth={checkAuth} />
              </div>
            </Route>
            <Route exact path="/my-account/products">
              <div className='page-container'>
                <AdminMenu sessionValid={sessionValid} roles={roles} logout={logout} userName={userName} title={'Products'} />
                <AccountProducts api={api} showMessage={showMessage} userId={userId} roles={roles} userMenu={userMenu} userName={userName} />
              </div>
            </Route>
            <Route exact path="/my-account/products/page/:page">
              <div className='page-container'>
                <AdminMenu sessionValid={sessionValid} roles={roles} logout={logout} userName={userName} title={'Products'} />
                <AccountProducts api={api} showMessage={showMessage} userId={userId} roles={roles} userMenu={userMenu} userName={userName} />
              </div>
            </Route>
            <Route exact path="/my-account/bundles">
              <div className='page-container'>
                <AdminMenu sessionValid={sessionValid} roles={roles} logout={logout} userName={userName} title={'Products'} />
                <AccountBundles api={api} showMessage={showMessage} userId={userId} roles={roles} userMenu={userMenu} userName={userName} />
              </div>
            </Route>
            <Route exact path="/my-account/add-product">
              <AccountProductAdd api={api} showMessage={showMessage} userId={userId} roles={roles} userMenu={userMenu} userName={userName} />
            </Route>
            <Route exact path="/my-account/social-tool">
              <SocialTool />
            </Route>
            <Route exact path="/tools/social">
              <SocialTool />
            </Route>
            <Route exact path="/my-account/add-bundle">
              <AccountBundleAdd api={api} showMessage={showMessage} userId={userId} roles={roles} userMenu={userMenu} userName={userName} />
            </Route>
            <Route exact path="/my-account/subscriptions">
              <div className='page-container'>
                <AdminMenu sessionValid={sessionValid} roles={roles} logout={logout} userName={userName} title={'Posts'} />
                <AccountSubs api={api} showMessage={showMessage} userId={userId} roles={roles} userMenu={userMenu} userName={userName} />
              </div>
            </Route>
            <Route exact path="/my-account/add-sub">
              <AccountSubAdd api={api} showMessage={showMessage} userId={userId} roles={roles} userMenu={userMenu} userName={userName} />
            </Route>
            <Route exact path="/my-account/sub/:id">
              <AccountSubEdit api={api} showMessage={showMessage} userId={userId} />
            </Route>
            <Route exact path="/my-account/posts">
              <div className='page-container'>
                <AdminMenu sessionValid={sessionValid} roles={roles} logout={logout} userName={userName} title={'Posts'} />
                <AccountPosts api={api} userId={userId} roles={roles} userMenu={userMenu} userName={userName} />
              </div>

            </Route>
            <Route exact path="/my-account/orders">
              <div className='page-container'>
                <AdminMenu sessionValid={sessionValid} roles={roles} logout={logout} userName={userName} title={'Orders'} />
                <AccountOrders api={api} userId={userId} roles={roles} userMenu={userMenu} userName={userName} showMessage={showMessage} />
              </div>

            </Route>
            <Route exact path="/my-account/pitches">
              <div className='page-container'>
                <AdminMenu sessionValid={sessionValid} roles={roles} logout={logout} userName={userName} title={'Pitches'} />
                <AccountPitches api={api} userId={userId} roles={roles} userMenu={userMenu} userName={userName} showMessage={showMessage} />
              </div>

            </Route>
            <Route exact path="/my-account/statements">
              <div className='page-container'>
                <AdminMenu sessionValid={sessionValid} roles={roles} logout={logout} userName={userName} title={'Orders'} />
                <AccountStatements api={api} userId={userId} roles={roles} userMenu={userMenu} userName={userName} />
              </div>

            </Route>
            <Route exact path="/my-account/pages/:id">
              <AccountPageEdit api={api} showMessage={showMessage} userId={userId} />
            </Route>
            <Route exact path="/my-account/outlets/:id">
              <AccountOutletEdit api={api} showMessage={showMessage} userId={userId} />
            </Route>
            <Route exact path="/my-account/people/:id">
              <AccountPersonEdit api={api} showMessage={showMessage} userId={userId} />
            </Route>
            <Route exact path="/my-account/pages">
              <div className='page-container'>
                <AdminMenu sessionValid={sessionValid} roles={roles} logout={logout} userName={userName} title={'Pages'} />
                <AccountPages api={api} userId={userId} roles={roles} userMenu={userMenu} userName={userName} />
              </div>
            </Route>
            <Route exact path="/my-account/posts/:id">
              <AccountPostEdit api={api} showMessage={showMessage} userId={userId} />
            </Route>
            <Route exact path="/my-account/edit">
              <AccountEdit api={api} showMessage={showMessage} />
            </Route>
            <Route exact path="/my-account/barcode">
              <Barcode api={api} showMessage={showMessage} />
            </Route>
            <Route exact path="/my-account/add-post">
              <AccountPostAdd api={api} showMessage={showMessage} userId={userId} />
            </Route>
            <Route exact path="/my-account/add-outlet">
              <AccountOutletAdd api={api} showMessage={showMessage} userId={userId} />
            </Route>
            <Route exact path="/my-account/add-person">
              <AccountPersonAdd api={api} showMessage={showMessage} userId={userId} />
            </Route>
            <Route exact path="/my-account/add-page">
              <AccountPageAdd api={api} showMessage={showMessage} userId={userId} />
            </Route>
            <Route exact path="/my-account/product/:id">
              <AccountProductEdit api={api} showMessage={showMessage} sessionValid={sessionValid} />
            </Route>
            <Route exact path="/my-account/bundle/:id">
              <AccountBundleEdit api={api} showMessage={showMessage} sessionValid={sessionValid} />
            </Route>

          </Switch>
        </Fragment>

        <Newsletter showMessage={showMessage} classes='bottom' />
        <div id="footer">
          <div className='sub-menu left'>
            <Link to={`/news`}>Contact</Link>
            <Link to={`/page/about`}>About</Link>
            <Link to={`/page/privacy`}>Privacy Policy</Link>
          </div>
          <div className='sub-menu right'>
            &copy; {moment().format('YYYY')} Sublunary Editions.
          </div>
        </div>
        {banner.text && <Message config={banner} showMessage={showMessage} />}
      </div>
    </Router>
  );
}
