import React, { useState, useEffect } from 'react';
import {
  Link,
  useParams
} from "react-router-dom";
import Paginator from '../../components/Paginator';
import SelectControl from '../../components/SelectControl';
import moment from 'moment';
import map from './mapfile.json';
import EditOrder from './edit.js';
import BulkOrder from './bulk.js';
import Labels from './labels.js';
import DeleteOrders from './delete.js';
import { makeRequest } from '../../Utils.js';

export default function AccountOrders({ userId, roles, userMenu, userName, showMessage }) {
  const [profile, setProfile] = useState({});

  const [error, setError] = useState('Unauthorized');
  const [login, setLogin] = useState();
  const [orders, setOrders] = useState([]);
  const [descending, setDescending] = useState(true);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [sum, setSum] = useState(0);
  const [product, setProduct] = useState('');
  const [selected, setSelected] = useState([]);
  const [per, setPer] = useState(20);
  const [keys, setKeys] = useState({ byName: {}, byProd: {} });
  const [expanded, setExpanded] = useState(null);
  const [shipStatus, setShipStatus] = useState('');

  const [creatingBulk, setCreatingBulk] = useState(false);
  const [viewingLabels, setViewingLabels] = useState(false);
  const [maybeDeleting, setMaybeDeleting] = useState(false);

  document.title = 'All Orders | Sublunary Admin';
  const getOrders = async () => {
    let data = await window.ASTERISM.makeRequest(`list-all-orders?descending=${descending}&per=${per}&page=${page}&product=${product}&shipStatus=${shipStatus}`, 'GET');
    setOrders(data.orders);
    setTotal(data.total);
    setSum(data.sum ? data.sum : 0);
  }

  const getPub = async () => {

    let data = await window.ASTERISM.makeRequest(`private-user`, 'GET');
    if (data.error) {
      setError(data.error);
    } else {
      setError('');
      setProfile(data);
    }
  }

  const getKeys = async () => {
    let data = await window.ASTERISM.makeRequest(`all-prices`, 'GET');
    setKeys(data);
  }

  useEffect(() => {
    if (userId && roles.publisher) {
      setSelected([]);
      getOrders();
    }
  }, [page, descending, product, per, shipStatus, total]);

  useEffect(() => {
    if (userId && roles.publisher) {
      getOrders();
      getKeys();
    }
  }, [userId]);

  useEffect(() => {
    setPage(0);
  }, [per, descending, product, per, shipStatus, total])

  const handleOrderClick = async (newproduct, event, order, item) => {
    if (!event.shiftKey) {
      if (newproduct == product) {
        setProduct('');
      } else {
        setProduct(newproduct);
      }
    } else if (!event.altKey && event.ctrlKey && map[newproduct] && map[newproduct].items) {
      let newOrder = order;
      map[newproduct].items.forEach((subitem) => {
        newOrder.items.push({
          name: subitem.name,
          product: subitem.product,
          quantity: newOrder.items[item].quantity,
          shipped: false,
          tracking: '',
          notes: '',
          date: ''
        });
      })
      let data = await window.ASTERISM.makeRequest(`update-order`, 'POST', newOrder);
      getOrders();
    } else if (event.altKey && event.ctrlKey) {
      // Delete this product!
    } else {
      let newOrder = order;
      newOrder.items[item].shipped = !order.items[item].shipped;
      let data = await window.ASTERISM.makeRequest(`update-order`, 'POST', newOrder);
      getOrders();
    }
  }

  const toggleSelected = (index) => {
    let thisIndex = selected.indexOf(index);
    if (thisIndex >= 0) {
      let newSelected = [...selected];
      newSelected.splice(thisIndex, 1);
      setSelected(newSelected);
    } else {
      let newSelected = [...selected];
      newSelected.push(index);
      setSelected(newSelected);
    }
  }

  const exportSelected = () => {
    let tsv = '';
    tsv += 'Order ID (required),'
    tsv += 'Order Date,'
    tsv += 'Order Value,'
    tsv += 'Requested Service,'
    tsv += 'Ship To - Name,'
    tsv += 'Ship To - Company,'
    tsv += 'Ship To - Address 1,'
    tsv += 'Ship To - Address 2,'
    tsv += 'Ship To - Address 3,'
    tsv += 'Ship To - State/Province,'
    tsv += 'Ship To - City,'
    tsv += 'Ship To - Postal Code,'
    tsv += 'Ship To - Country,'
    tsv += 'Ship To - Phone,'
    tsv += 'Ship To - Email,'
    tsv += 'Total Weight in Oz,'
    tsv += 'Dimensions - Length,'
    tsv += 'Dimensions - Width,'
    tsv += 'Dimensions - Height,'
    tsv += 'Notes - From Customer,'
    tsv += 'Notes - Internal,'
    tsv += 'Gift Wrap?,'
    tsv += 'Gift Message\n';
    orders.forEach((order, index) => {
      if (selected.indexOf(index) !== -1) {
        tsv += `"${order._id + new Date().getTime()}",`
        tsv += `"${moment(order.created).format('L')}",,,`
        tsv += `"${order.name}",,`
        tsv += `"${order.address.line1 ? order.address.line1.replace('#', 'no.') : ''}",`
        tsv += `"${order.address.line2 ? order.address.line2.replace('#', 'no.') : ''}",,`
        tsv += `"${order.address.state ? order.address.state : ''}",`
        tsv += `"${order.address.city ? order.address.city : ''}",`
        tsv += `"${order.address.postal_code ? order.address.postal_code : ''}",`
        tsv += `"${order.address.country ? order.address.country : ''}",,`
        tsv += `"${order.email}",,,,,,"${order.items.map((item) => keys.byProd[item.product]).join(',')}"`
        tsv += '\n';
      }
    });
    const hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(tsv);
    hiddenElement.target = '_blank';
    hiddenElement.download = `orders-${moment().format('MMM-DD-YYYY-hh-mm-a')}.csv`;
    hiddenElement.click();
  }

  const uuid = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  const updateOrder = async (editedOrder) => {
    for (let i = 0; i < editedOrder.items.length; i++) {
      if (editedOrder.items[i].quantity == 0) {
        editedOrder.items.splice(i, 1);
      }
    }
    if (!editedOrder._id) {
      editedOrder._id = uuid();
    }
    let data = await window.ASTERISM.makeRequest(`update-order`, 'POST', editedOrder);
    getOrders();
    setExpanded(null);
  }

  const getSelectedTotal = () => {
    let total = 0;
    selected.forEach((index) => {
      orders[index].items.forEach((item) => {
        total += item.quantity;
      });
    });
    return total;
  }

  const toggleAllSelected = () => {
    if (selected.length == orders.length) {
      setSelected([]);
    } else {
      setSelected([...Array(orders.length).keys()])
    }
  }

  const sendToShip = async (order) => {
    let newOrder = {
      products: [],
      shipping: {
        method: '',
        cost: 0
      },
      express: false,
      address: {
        name: '',
        name: order.name,
        lineOne: order.address.line1,
        lineTwo: order.address.line2 ?? '',
        postalCode: order.address.postal_code,
        city: order.address.city,
        state: order.address.state,
        phone: order.phone ?? '',
        country: order.address.country
      }
    }

    let items = await window.ASTERISM.makeRequest(`order-products?id=${order._id}`);
    let titles = 0;
    let books = 0;
    let weight = 0;
    let publisher = 'ffe295f2bd48647c7f354d7cb024bc24';

    // Get the products;
    for (let i = 0; i < items.length; i++) {
      let isbn = await window.ASTERISM.makeRequest(`product-by-price?price=${items[i].price.id}`);
      let data = await fetch(`https://asterismbooks.com/api/product?id=${isbn.id}`);
      try {
        let product = await data.json();
        if (product.error) {
          continue;
        }
        product.quantity = items[i].quantity;
        titles += 1;
        books += product.quantity;
        weight += product?.dimensions?.weight ?? 10;
        newOrder.products.push(product);
      } catch (e) {

      }
    }

    // Get shipping!
    let request = {
      ounces: weight,
      country: order.address.country,
      postalCode: order.address.postalCode,
      express: false,
      free: false,
      items: newOrder.products.length,
      noPacking: true,
    }
    let rateData = await fetch('https://asterismbooks.com/api/shipping-rates', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(request)
    });
    let rate = await rateData.json();
    newOrder.shipping.cost = rate.shipmentCost;
    newOrder.shipping.method = rate.method;
    newOrder.subtotal = (books * .65) + (titles * .65) + 1.50;
    newOrder.publisher = publisher;
    newOrder.total = newOrder.subtotal + newOrder.shipping.cost;
    let orderData = {
      total: newOrder.total,
      publisher: publisher,
      orders: [newOrder]
    };

    let response = await fetch('https://asterismbooks.com/api/dropship', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(orderData)
    });
    let data = await response.json();
    if (data.ok) {
      showMessage({ text: 'Item has been sent to ship!', temp: true, type: 'success', label: 'Success!' });
    } else {
      console.log('Oh No!');
    }
  }

  const createBulkOrder = async (bulkData) => {
    let data = await window.ASTERISM.makeRequest(`create-bulk-subs`, 'POST', bulkData);
    getOrders();
    setCreatingBulk(false);
  }

  if (!userId || !roles.publisher) {
    return (
      <div className='space'>You are not authorized to view this page.</div>
    )
  }

  return (
    <div>
      <div className='admin-full '>
        <div className='standard-header'>
          <div className='all-wide'>
            <span>{total.toLocaleString('en')} orders / {sum.toLocaleString('en')} books {selected.length > 0 && <span>| {selected.length} selected / {getSelectedTotal()} books</span>}</span>
            <div>
              <button className='secondary-submit mr-12' onClick={exportSelected}>Export Selected</button>
              <button className='secondary-submit mr-12' onClick={() => { setExpanded(-1) }}>Add New</button>
              <button className='secondary-submit mr-12' onClick={() => { setViewingLabels(!viewingLabels) }}>Get Labels</button>
              <button className='secondary-submit mr-12' onClick={() => { setCreatingBulk(!creatingBulk) }}>Add Bulk</button>
              <button className='secondary-submit' onClick={() => { setMaybeDeleting(!maybeDeleting) }}>Delete Selected</button>
            </div>
          </div>
          <div className='table-page-controls'>
            <SelectControl
              label={'Book'}
              value={product}
              change={(value) => { setProduct(value) }}
              options={[
                {
                  value: '',
                  label: '--'
                }
              ].concat(Object.entries(keys.byName).map((opt) => { return { value: opt[1], label: [opt[0]] } }))}
            />
            <SelectControl
              label={'Ship Status'}
              value={shipStatus}
              change={(value) => { setShipStatus(value) }}
              disabled={!product}
              options={[
                {
                  value: '',
                  label: '--'
                },
                {
                  value: 'shipped',
                  label: 'Shipped'
                },
                {
                  value: 'unshipped',
                  label: 'Un-shipped'
                },
              ]}
            />
            <SelectControl
              label={'Per Page'}
              value={per}
              change={(value) => { setPer(value) }}
              options={[
                {
                  value: 20,
                  label: 20
                },
                {
                  value: 50,
                  label: 50
                },
                {
                  value: 100,
                  label: 100
                },
                {
                  value: total,
                  label: 'All'
                }
              ]}
            />
          </div>
          {orders.length > 0 && <div className='mt-24 mb-24 text-center'><Paginator value={page} maxPages={Math.ceil(total / per)} change={setPage} /></div>}

        </div>
        <div className='table-header table-item admin-table'>
          <div className='order-col flex-small'>
            <div
              className={`checkbox-prototype ${selected.length == orders.length ? 'selected' : 'not-selected'}`}
              onClick={() => { toggleAllSelected() }}
            ></div>
          </div>
          <div className='order-col flex-1'>Name / Email</div>
          <div className='order-col flex-1'>Date</div>
          <div className='order-col flex-1'>Country</div>
          <div className='order-col flex-2'>Items</div>
          <div className='order-col flex-1'></div>
        </div>
        <div className='admin-orders'>
          {orders.length == 0 && <div><em>There are no orders yet.</em></div>}
          {orders.map((order, orderIndex) =>
            <div className='table-item' key={order._id} >
              <div className='order-col flex-small'>
                <div
                  className={`checkbox-prototype ${selected.indexOf(orderIndex) !== -1 ? 'selected' : 'not-selected'}`}
                  onClick={() => { toggleSelected(orderIndex) }}
                >
                </div>
              </div>
              <div className='order-col flex-1 bold'><a onClick={() => { setExpanded(orderIndex) }}>{order.name}</a> <span className='table-smaller'>{order.email}</span></div>
              <div className='order-col flex-1 bold'>{moment(order.created).format('LLL')}</div>
              <div className='order-col flex-1 bold'>{order.address && order.address.country}</div>
              <div className='order-col flex-2 bold'>
                {order.items && order.items.map((item, index) =>
                  <span
                    key={item.product}
                    onClick={(e) => { handleOrderClick(item.product, e, order, index) }}
                    className={`order-item-pill ${item.shipped ? 'shipped' : 'unshipped'}`}>
                    {keys.byProd[item.product] ? keys.byProd[item.product] : item.name} x{item.quantity}
                  </span>
                )}
              </div>
              <div className='order-col flex-1 bold'><button className='secondary-submit' onClick={() => { sendToShip(order) }}>Ship</button></div>
            </div>
          )}
          {orders.length > 0 && <div className='mt-24 mb-24 text-center'><Paginator value={page} maxPages={Math.ceil(total / per)} change={setPage} /></div>}
        </div>
      </div>
      {expanded !== null && <EditOrder order={orders[expanded] ? orders[expanded] : null} keys={keys} setExpanded={setExpanded} updateOrder={updateOrder} />}
      {creatingBulk && <BulkOrder setExpanded={setCreatingBulk} keys={keys} create={createBulkOrder} />}
      {viewingLabels && <Labels orders={orders} selected={selected} setViewingLabels={setViewingLabels} />}
      {maybeDeleting && <DeleteOrders orders={orders} selected={selected} cancel={setMaybeDeleting} setSelected={setSelected} getOrders={getOrders} />}
    </div>
  )
}
